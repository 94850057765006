import { useIntl } from 'react-intl'

import { useCallback } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import findImageWithQuality from '../../utils/findImageWithQuality'
import ProblemGray from '../../img/problem_gray.svg'
import SuggestionGray from '../../img/suggestion_gray.svg'
import ReportGray from '../../img/report_gray.svg'
import ProcessGray from '../../img/process_gray.svg'
import ClosedGray from '../../img/finished_gray.svg'
import SupportGray from '../../img/support_gray.svg'
import ShareGray from '../../img/share_gray.svg'
import avatar from '../../img/nophoto.jpg'
import { TranslationsService } from '../../services'

const ReportCard = ({
  report,
  support,
  createSupport,
  removeSupport,
  listIssues,
}) => {
  const {
    id,
    title,
    reportType,
    status,
    files,
    category,
    created_at,
    supportCount,
  } = report

  let type = ''
  let reportStatus = ''
  let statusImg = ''
  if (reportType === 'problem') {
    type = 'problem2'
  } else {
    type = 'suggestion2'
  }

  if (status === 'reported') {
    reportStatus = 'submitted'
    statusImg = ReportGray
  } else if (status === 'in_progress') {
    reportStatus = 'inProcedure'
    statusImg = ProcessGray
  } else {
    reportStatus = 'closed2'
    statusImg = ClosedGray
  }

  const supportPressed = useCallback(
    async (e) => {
      e.preventDefault()
      if (!support.supported) {
        await createSupport(id)

        await listIssues()
      } else {
        await removeSupport(support.supportId)
        await listIssues()
      }
    },
    [
      support.supported,
      support.supportId,
      createSupport,
      id,
      listIssues,
      removeSupport,
    ],
  )

  const share = (e) => {
    e.preventDefault()
    if (navigator.share) {
      navigator
        .share({
          url: `${process.env.RAZZLE_WEB_URL}/reportDetails/${id}`,
          // extra, optional options
          fbId: `${process.env.RAZZLE_FACEBOOK_ID}`,
          hashtags: ['mZaednica'],
        })
        // eslint-disable-next-line no-console
        .then(() => console.log('Yay, you shared it :)'))
        .catch((error) =>
          console.error("Oh noh! You couldn't share it! :'(\n", error),
        )
    }
  }

  const { formatMessage } = useIntl()

  return (
    <>
      <Link to={`/reportDetails/${id}`}>
        <div className="bg-white max-w-full h-100 mx-auto px-18 py-9 rounded-xl shadow-xl">
          <div className="flex max-w-full mb-3">
            <figure className="mr-3.5 mb-3 2xl:mb-0 w-16 h-16 flex-shrink-0">
              <img
                src={reportType === 'problem' ? ProblemGray : SuggestionGray}
                className="w-14 h-14 md:w-72 md:h-72"
                alt="Report type icon"
              />
            </figure>
            <div className="flex flex-col flex-1 overflow-hidden">
              <div className="flex flex-wrap text-lightGray2 text-base align-middle">
                {formatMessage({ id: type })}&#8226;{' '}
                {moment(created_at).format('DD.MM.YYYY')} &#8226;
                {'  '}
                <div className="ml-1 flex items-center">
                  <img
                    alt="someAlt"
                    src={statusImg}
                    className="w-3 h-3 inline-block align-middle mr-1"
                  />{' '}
                  {formatMessage({ id: reportStatus })}
                </div>
              </div>
              <div className="flex flex-col mt-1">
                <h5 className="text-footer text-lg md:text-xl font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden">
                  {title}
                </h5>
                <h6 className="text-footer text-base font-extralight overflow-ellipsis whitespace-nowrap overflow-hidden">
                  {category ? (
                    TranslationsService.getLanguage() === 'mk' ? (
                      category.name
                    ) : (
                      category.name_sq
                    )
                  ) : (
                    <>&nbsp;</>
                  )}
                </h6>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex items-center justify-center bg-gray-50">
            <img
              alt="someAlt"
              className="self-center object-contain w-full h-52"
              src={
                files.length === 0
                  ? avatar
                  : `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                      files[0],
                      'small',
                    )}`
              }
            />
          </div>
          <div className="flex justify-between flex-wrap">
            <span className="text-base font-extrabold text-darkGray1 inline-flex mt-7 items-center">
              {supportCount}
              <img
                alt="someAlt"
                src={SupportGray}
                width="36"
                height="36"
                className="w-9 h-auto mx-2.5"
              />
              {support?.supported ? (
                <button
                  type="button"
                  className="text-base font-extrabold text-blue focus:outline-none"
                  onClick={supportPressed}
                >
                  {formatMessage({ id: 'cancelSupport' })}
                </button>
              ) : (
                <button
                  type="button"
                  className="text-base font-extrabold text-darkGray1 focus:outline-none"
                  onClick={supportPressed}
                >
                  {formatMessage({ id: 'support' })}
                </button>
              )}
            </span>
            <span className="text-base font-extrabold text-darkGray1 inline-flex mt-7 items-center">
              <img
                alt="someAlt"
                src={ShareGray}
                width="26"
                height="auto"
                className="w-7 h-auto mx-2.5"
              />
              <button
                type="button"
                className="text-base font-extrabold text-darkGray1 focus:outline-none"
                onClick={share}
              >
                {formatMessage({ id: 'share' })}
              </button>
            </span>
          </div>
        </div>
      </Link>
    </>
  )
}
export default ReportCard
