import { useEffect } from 'react'
import { TranslationsService } from '../../services'
import { LANGUAGES } from '../../i18n/settings/languages'
import { connect } from 'react-redux'
import {
  setLanguage,
  getLanguages,
  getCurrentMunicipality,
} from '@mzaednica/core'
import MkFlag from './images/mk_flag.svg'
import AlFlag from './images/al_flag.svg'

const LanguageSelector = ({ setLanguage }) => {
  useEffect(() => {
    const savedLanguage = TranslationsService.getLanguage()
    if (savedLanguage) {
      setLanguage({ language: savedLanguage })
    }
  }, [setLanguage])

  const handleLanguageChange = (newLang) => {
    TranslationsService.saveLanguage({ language: newLang })
    setLanguage({ language: newLang })
  }

  return (
    <div>
      <button
        onClick={() => handleLanguageChange(LANGUAGES.MK)}
        className="m-2"
      >
        <img src={MkFlag} alt="Mk flag" width="28" height="28" />
      </button>
      <button
        onClick={() => handleLanguageChange(LANGUAGES.SQ)}
        className="m-2"
      >
        <img src={AlFlag} alt="Al flag" width="28" height="28" />
      </button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  languages: getLanguages(state),
  currentMunicipality: getCurrentMunicipality(state),
})

const mapDispatchToProps = {
  setLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
