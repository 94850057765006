import { useIntl } from 'react-intl'
import { useCallback, useMemo, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { getCategories, createIssue } from '@mzaednica/core'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import EditIcon from '../../img/edit.svg'
import CameraWhite from '../../img/camera_white.svg'
import { GoogleMap, Marker } from '@react-google-maps/api'
import isBrowser from '../../utils/isBrowser'
import { connect } from 'react-redux'
import graySVG from '../../utils/graySVG'
import withAuthentication from '../../utils/withAuthentication'
import MultipleMediaField from '../../components/Inputs/MultipleMediaField'
import { useEffect } from 'react'
import { CreateReportSchema } from './validation/schema'
import { TranslatedErrorMessage } from '../../components/TranslatedErrorMessage'
import { reportTypes } from './utils/labels'
import { TranslationsService } from '../../services'
const initialValues = {
  reportType: 'problem',
  title: '',
  description: '',
  location: null,
  category: 1,
  files: [],
}

const center = {
  lat: 41.9947898,
  lng: 21.4398863,
}

const containerStyle = {
  width: '400px',
  height: '400px',
}

const CreateReport = ({ categories, createIssue }) => {
  const history = useHistory()

  const [location, setLocation] = useState(null)

  const geocoder = useMemo(() => {
    if (!isBrowser()) {
      return undefined
    }

    return new window.google.maps.Geocoder()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const submitForm = useCallback(
    async (values) => {
      const encodeVals = { ...values }
      delete encodeVals.files
      const formData = new FormData()
      values.files.forEach((x) => formData.append('files.files', x))
      formData.append('data', JSON.stringify(encodeVals))
      try {
        const issue = await createIssue(formData)
        history.push(`/reportDetails/${issue.id}`)
      } catch (e) {
        console.error(e)
        window.scrollTo(20, 0)
      }
    },
    [createIssue, history],
  )

  useEffect(() => {
    // TODO: This is a hack, we need to find a real solution for it
    const removeDialog = () => {
      const dialog = document.querySelector(
        '.SDYZEU-keyboard-shortcuts-dialog-view',
      )
      if (dialog) {
        dialog.remove()
      }
    }

    const interval = setInterval(removeDialog, 100)
    return () => clearInterval(interval)
  }, [])

  const { formatMessage } = useIntl()
  return (
    <>
      <Header />
      <section className="bg-lightGray4">
        <Formik
          initialValues={initialValues}
          validationSchema={CreateReportSchema}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={submitForm}
        >
          {({ isSubmitting }) => (
            <Form className="container w-840 max-w-full pt-10 pb-10">
              <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center">
                {formatMessage({ id: 'report2' })}
              </h2>
              <div className="flex justify-center mt-6 mb-6">
                {reportTypes.map((reportType, idx) => (
                  <label htmlFor={reportType.value} key={idx}>
                    <Field
                      id={reportType.value}
                      value={reportType.value}
                      name="reportType"
                      type="radio"
                      className="h-5 w-5 md:ml-3"
                    />
                    <span className="ml-1.5 mr-10 text-lg md:text-xl">
                      {TranslationsService.getLanguage() === 'mk'
                        ? reportType.label_mk
                        : reportType.label_sq}
                    </span>
                  </label>
                ))}
              </div>
              <div className="container max-h-48 flex flex-row overflow-hidden overflow-x-auto space-x-2">
                <Field name="files">
                  {({ field, form: { setFieldValue } }) => (
                    <MultipleMediaField
                      field={field}
                      setFieldValue={setFieldValue}
                      id="files"
                    />
                  )}
                </Field>
              </div>
              <div className="container bg-lightGray3 h-48 flex flex-col justify-center items-center mt-8 md:mt-14 px-4 md:px-0">
                <label
                  htmlFor="files"
                  className="text-white text-sm md:text-lg md:mt-4"
                >
                  <img
                    src={CameraWhite}
                    alt="Upload icon"
                    width="60"
                    height="auto"
                    className="mx-auto mb-2"
                  />
                  <span>{formatMessage({ id: 'attachPhoto' })}</span>
                </label>
                <span className="text-red text-center">
                  <TranslatedErrorMessage name={'files'} />
                </span>
              </div>
              <div className="container px-4 md:px-0 mt-10">
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: 'report2' })}
                  </span>
                  <div className="inline-flex items-center justify-between">
                    <Field
                      name="title"
                      type="text"
                      className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
                      placeholder={formatMessage({ id: 'enterTitle' })}
                    />
                    <img
                      alt="someAlt"
                      src={EditIcon}
                      width="18"
                      height="auto"
                    />
                    <br />
                  </div>
                </label>
                <TranslatedErrorMessage name="title" />
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: 'location' })}
                  </span>
                  <div className="inline-flex items-center justify-between">
                    <Field name="location">
                      {({ field, form }) => {
                        const mapClick = (data) => {
                          if (!data || !data.latLng) return
                          const loc = {
                            lat: data.latLng.lat(),
                            lng: data.latLng.lng(),
                          }
                          setLocation(loc)
                          geocoder.geocode(
                            { location: loc },
                            (results, status) => {
                              if (status === 'OK') {
                                form.setFieldValue(field.name, {
                                  name:
                                    results && results.length
                                      ? `${results[0].address_components[1].short_name} ${results[0].address_components[0].short_name}`
                                      : '',
                                  address:
                                    results && results.length
                                      ? `${results[0].formatted_address}`
                                      : '',
                                  latLng: {
                                    longitude: loc.lng,
                                    latitude: loc.lat,
                                  },
                                })
                              }
                            },
                          )
                        }
                        return (
                          <div className="flex flex-col">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={center}
                              zoom={10}
                              onClick={mapClick}
                            >
                              <>
                                {location && (
                                  <Marker position={location}></Marker>
                                )}
                              </>
                            </GoogleMap>
                            <p>{field.value ? field.value.name : ''}</p>
                          </div>
                        )
                      }}
                    </Field>
                  </div>
                </label>
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: 'description' })}
                  </span>
                  <div className="inline-flex items-center justify-between">
                    <Field
                      name="description"
                      type="text"
                      className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
                      placeholder={formatMessage({ id: 'enterDescription' })}
                    />
                    <img
                      alt="someAlt"
                      src={EditIcon}
                      width="18"
                      height="auto"
                    />
                    <br />
                  </div>
                </label>
                <TranslatedErrorMessage name={'title'} />
                <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center mt-16">
                  {formatMessage({ id: 'inquiryAbout' })}
                </h2>
                <section className="grid grid-cols-2 sm:grid-cols-4 gap-x-8 gap-y-8 md:gap-y-20 mt-14 px-4 md:px-0">
                  <Field name="category">
                    {({ field: { name, value }, form }) => (
                      <>
                        {categories.isFetched &&
                          categories.listData.map((category) => {
                            const isSelected = value === category.id
                            return (
                              <button
                                key={category.id}
                                className="flex flex-col items-center focus:outline-none "
                                type="button"
                                onClick={() => {
                                  form.setFieldValue(name, category.id)
                                }}
                              >
                                <SVG
                                  key={`${category.id}_${isSelected}`}
                                  src={`${process.env.RAZZLE_API_URL}${category.featuredImage.url}`}
                                  width="120"
                                  height="100"
                                  fill="gray"
                                  preProcessor={isSelected ? null : graySVG}
                                />
                                <p
                                  className={`font-semibold text-base md:text-22 text-center mt-4 ${
                                    isSelected ? 'text-blue' : 'text-lightGray5'
                                  }`}
                                >
                                  {TranslationsService.getLanguage() === 'mk'
                                    ? category.name
                                    : category.name_sq}
                                  {}
                                </p>
                              </button>
                            )
                          })}
                      </>
                    )}
                  </Field>
                  <TranslatedErrorMessage name={'category'} />
                </section>
              </div>
              <div className="flex justify-end mt-10 md:mt-14 px-4 md:px-0">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-md md:text-lg font-semibold rounded-xl text-white bg-blue hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-20"
                >
                  {formatMessage({ id: 'report' })}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  categories: getCategories(state),
})

const mapDispatchToProps = {
  createIssue,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(CreateReport),
)
