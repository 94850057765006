/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useIntl } from 'react-intl'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, FieldArray } from 'formik'
import SVG from 'react-inlinesvg'
import { useLocation } from 'react-router-dom'
import {
  getMunicipalities,
  getInterests,
  updateMe,
  getProfile,
  getUserDetails,
  uploadFile,
  getIndustries,
  listIndustries,
  openModal,
  closeModal,
  setLanguage,
} from '@mzaednica/core'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import ProfileMenuIcon from '../../img/profile_menu_gray.svg'
import EditIcon from '../../img/edit.svg'
import graySVG from '../../utils/graySVG'
import { useHistory } from 'react-router'
import withAuthentication from '../../utils/withAuthentication'
import { CompleteProfileSchema } from './validation/schema'
import { TranslatedErrorMessage } from '../../components/TranslatedErrorMessage'
import showPassword from '../../img/showPassword.png'
import hiddePassword from '../../img/hiddePassword.png'
import { DELETE_PROFILE_MODAL_ID } from '../../consts/modalIds'
import DeleteAccountModal from '../../components/Modals/DeleteProfileModal'
import { TranslationsService } from '../../services/index'
import { accountTypes, genders } from './utils/labels'

const CompleteProfile = ({
  municipalities,
  interests,
  userDetails,
  updateMe,
  getProfile,
  industries,
  listIndustries,
  openModal,
  setLanguage,
}) => {
  const { isFetched, listData } = municipalities
  const history = useHistory()

  const location = useLocation()
  const editProfile = location.state?.editProfile

  const [initialValues, setInitialValues] = useState({
    municipality: 0,
    accountType: 'individual',
    firstName: '',
    familyName: '',
    gender: 'Male',
    companyName: '',
    industry: 0,
    interests: [],
    language: 'mk',
  })

  const [to_text, toggleType] = useState(true)
  const [accountType, setAccountType] = useState(true)

  const toggleInputType = () => {
    toggleType(!to_text)
  }

  useEffect(() => {
    if (isFetched && !initialValues.municipality) {
      setInitialValues((prev) => ({
        ...prev,
        municipality: listData[0].id,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initialValues), isFetched, listData])

  useEffect(() => {
    if (isFetched) {
      listIndustries()
      setInitialValues((prev) => ({
        ...prev,
        municipality: listData[0].id,
      }))
    }
  }, [isFetched, listData, listIndustries])

  useEffect(() => {
    if (!userDetails.isFetched && !userDetails.isFetching) {
      history.push('/')
    } else if (userDetails.userData) {
      const userData = userDetails.userData
      const newInitialVals = {
        ...initialValues,
        firstName: userData.firstName || '',
        familyName: userData.familyName || '',
        accountType: userData.accountType || '',
        birth: userData.birth,
        gender: userData.gender || 'Male',
        companyName: userData.companyName || '',
        industry: userData.industry || 0,
        interests: userData.interests
          ? userData.interests.map((x) => x.id)
          : [],
        municipality: userData.municipality
          ? userData.municipality.id
          : initialValues.municipality,
        language: TranslationsService.getLanguage(),
      }
      setInitialValues(newInitialVals)
      setAccountType(userDetails?.userData?.accountType === 'individual')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, JSON.stringify(initialValues), userDetails])

  const [, setPhoto] = useState(null)
  const [photoDataURL, setPhotoDataURL] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const profilePhotoChanged = useCallback(
    async (e) => {
      if (e.target.files.length > 0) {
        setIsUploading(true)
        setPhotoDataURL(URL.createObjectURL(e.target.files[0]))
        const formData = new FormData()
        formData.append('files', e.target.files[0])
        formData.append('source', 'users-permissions')
        formData.append('ref', 'user')
        formData.append('refId', userDetails.userData.id)
        formData.append('field', 'profilePicture')
        const fileData = await uploadFile(formData)
        setIsUploading(false)
        setPhoto(fileData[0])
      }
    },
    [userDetails],
  )
  const chooseAccountType = (setFieldValue, e) => {
    setFieldValue('accountType', e.target.value)
    setAccountType(e.target.value === 'individual')
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMunicipalityById = (id) =>
    municipalities?.listData?.find(
      ({ id: municipalityId }) => `${municipalityId}` === `${id}`,
    )

  const submitForm = useCallback(
    async (values) => {
      const municipality = getMunicipalityById(values.municipality)
      if (municipality.numberOfLanguages <= 1) {
        TranslationsService.saveLanguage({
          language: TranslationsService.getDefaultLanguage(),
        })
        setLanguage({ language: TranslationsService.getDefaultLanguage() })
      }

      if (values.language) {
        TranslationsService.saveLanguage({
          language: values.language,
        })
        setLanguage({ language: values.language })
      }

      await updateMe({
        ...values,
        profileComplete: true,
      })
      await getProfile()

      history.push('/')
    },
    [getMunicipalityById, updateMe, getProfile, history, setLanguage],
  )

  const openDeleteProfileModal = () => {
    openModal(DELETE_PROFILE_MODAL_ID)
  }

  const { formatMessage } = useIntl()

  return (
    <>
      <Header />
      <DeleteAccountModal />
      <section className="bg-lightGray4">
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          enableReinitialize
          validationSchema={CompleteProfileSchema}
        >
          {({ setFieldValue, values }) => (
            <Form className="container w-840 max-w-full pt-20 pb-20 md:pt-103 md:pb-48">
              <label className="block text-center px-4 md:px-0">
                <span className="text-lightGray5 font-semibold text-xl md:text-2xl">
                  {formatMessage({ id: 'municipality' })}
                </span>
                <Field
                  as="select"
                  name="municipality"
                  className="block w-376 max-w-full mt-5 mx-auto shadow-lg px-6 py-5 text-sm md:text-xl font-semibold text-lightGray5 rounded-3xl bg-white border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                >
                  {listData &&
                    listData.map((municipality) => (
                      <option key={municipality.id} value={municipality.id}>
                        {TranslationsService.getLanguage() === 'mk'
                          ? municipality.name
                          : municipality.name_sq}
                      </option>
                    ))}
                </Field>
                <TranslatedErrorMessage name={'municipality'} />
              </label>

              {editProfile &&
                getMunicipalityById(values.municipality)?.numberOfLanguages >
                  1 && (
                  <label className="block text-center px-4 md:px-0">
                    <span className="text-lightGray5 font-semibold text-xl">
                      {formatMessage({ id: 'chooseLanguage' })}
                    </span>
                    <Field
                      as="select"
                      name="language"
                      className="block w-1/3 mt-5 mx-auto shadow-lg px-6 py-5 text-sm md:text-xl font-semibold text-lightGray5 rounded-3xl bg-white border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    >
                      <option value={TranslationsService.languages.MK}>
                        {formatMessage({
                          id: TranslationsService.languages.MK,
                        })}
                      </option>
                      <option value={TranslationsService.languages.SQ}>
                        {formatMessage({
                          id: TranslationsService.languages.SQ,
                        })}
                      </option>
                    </Field>
                  </label>
                )}
              <h2 className="text-xl md:text-2xl	font-semibold text-lightGray5 text-center mt-16">
                {formatMessage({ id: 'typeOfProfile' })}
              </h2>
              <div className="px-4 md:px-0 mt-10">
                {accountTypes.map((accountType) => (
                  <div
                    key={accountType.value}
                    className="flex items-center border-b border-lightGray3 pb-6 pt-8"
                  >
                    <Field
                      id={accountType.value}
                      name="accountType"
                      type="radio"
                      className="h-5 w-5"
                      value={accountType.value}
                      onChange={chooseAccountType.bind(this, setFieldValue)}
                    />
                    <label
                      htmlFor={accountType.value}
                      className="ml-4 md:ml-6 block text-base md:text-xl font-semibold text-darkGray1"
                    >
                      {formatMessage({
                        id: accountType.label,
                      })}
                    </label>
                  </div>
                ))}
                <TranslatedErrorMessage name={'accountType'} />
              </div>
              <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center mt-16">
                {formatMessage({ id: 'myProfile' })}
              </h2>
              <div className="flex flex-col justify-center items-center mt-10">
                <img
                  src={photoDataURL ? photoDataURL : ProfileMenuIcon}
                  alt="Profile picture"
                  className="w-28 h-28 bg-gray-300 rounded-full border-2 border-blue object-cover"
                />
                <label
                  htmlFor="profilePic"
                  className="text-blue font-medium text-base md:text-lg mt-4 mb-11"
                >
                  {formatMessage({ id: 'attachPhoto' })}
                </label>
                <input
                  id="profilePic"
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={profilePhotoChanged}
                  multiple
                />
              </div>
              {accountType ? (
                <div>
                  <div className="px-4 md:px-0">
                    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {formatMessage({ id: 'name' })}
                      </span>
                      <div className="inline-flex items-center justify-between">
                        <Field
                          name="firstName"
                          type="text"
                          placeholder={formatMessage({ id: 'enterName' })}
                          className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                        />

                        <img src={EditIcon} width="18" height="auto" />
                      </div>
                    </label>
                    <TranslatedErrorMessage name={'firstName'} />
                    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {formatMessage({ id: 'surname' })}
                      </span>
                      <div className="inline-flex items-center justify-between">
                        <Field
                          name="familyName"
                          type="text"
                          placeholder={formatMessage({ id: 'еnterSurname' })}
                          className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                        />
                        <img src={EditIcon} width="18" height="auto" />
                      </div>
                    </label>
                    <TranslatedErrorMessage name={'familyName'} />
                    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {formatMessage({ id: 'yearOfBirth' })}
                      </span>
                      <Field
                        type="date"
                        name="birth"
                        className="w-auto bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 text-lg md:text-xl"
                      />
                    </label>
                    <TranslatedErrorMessage name={'birth'} />

                    <div className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {formatMessage({ id: 'sex' })}
                      </span>
                      <div>
                        {genders.map((gender) => (
                          <label
                            key={gender.value}
                            htmlFor={gender.value}
                            className="mr-10"
                          >
                            <Field
                              id={gender.value}
                              value={gender.value}
                              name="gender"
                              type="radio"
                              className="h-5 w-5"
                            />
                            <span className="text-lightGray3 ml-1.5 text-lg md:text-xl">
                              {gender.label}
                            </span>
                          </label>
                        ))}
                      </div>
                      <TranslatedErrorMessage name={'gender'} />
                    </div>
                  </div>
                  {editProfile ? (
                    <div className="flex flex-col">
                      <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5 mb-10">
                        <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                          {formatMessage({ id: 'newPassword' })}
                        </span>
                        <div className="inline-flex items-center justify-between">
                          <div>
                            <Field
                              name="password"
                              className="w-auto bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 text-lg md:text-xl"
                              type={to_text ? 'password' : 'text'}
                            />
                            <div className="inline-flex w-auto justify-end items-center -ml-7 pr-3">
                              <button
                                type="button"
                                className="pr-3 text-md font-bold outline-none focus:outline-none text-blue disabled:opacity-20"
                                onClick={() => toggleInputType()}
                              >
                                {to_text ? (
                                  <img
                                    src={showPassword}
                                    width="18"
                                    height="auto"
                                    alt="img"
                                  />
                                ) : (
                                  <img
                                    src={hiddePassword}
                                    width="18"
                                    height="auto"
                                    alt="img"
                                  />
                                )}
                              </button>
                            </div>
                          </div>
                          <img src={EditIcon} width="18" height="auto" />
                        </div>
                      </label>
                      <button
                        className="w-44 self-end"
                        type="button"
                        onClick={openDeleteProfileModal}
                      >
                        <span className="text-red underline">
                          {formatMessage({ id: 'deleteProfile' })}
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  <div className="px-4 md:px-0">
                    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5 items-center">
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {formatMessage({ id: 'title' })}
                      </span>
                      <div className="inline-flex items-center justify-between">
                        <Field
                          name="companyName"
                          type="text"
                          placeholder={formatMessage({ id: 'title' })}
                          className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                        />
                        <img src={EditIcon} width="18" height="auto" />
                      </div>
                    </label>
                    <TranslatedErrorMessage name={'companyName'} />
                  </div>
                  <div className="mt-5">
                    <div className="flex flex-row items-center">
                      <label
                        className="text-darkGray1 text-lg md:text-xl font-semibold w-auto"
                        htmlFor="interest"
                      >
                        {formatMessage({ id: 'filed' })}
                      </label>

                      <Field
                        as="select"
                        name="industry"
                        className="block w-full bg-transparent border-transparent focus:border-transparent focus:bg-transparent text-center ml-3"
                      >
                        {industries?.listData.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      <TranslatedErrorMessage name={'industry'} />
                    </div>
                  </div>
                </div>
              )}
              <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center mt-16">
                {formatMessage({ id: 'fieldOfInterest' })}
              </h2>
              <section className="grid grid-cols-2 sm:grid-cols-4 gap-x-8 gap-y-8 md:gap-y-20 mt-14 px-4 md:px-0">
                <FieldArray name="interests">
                  {({ push, remove, form }) => {
                    const interestsVal = form.values.interests
                    return (
                      <>
                        {interests.isFetched &&
                          interests.listData.map((interest) => {
                            const selectedIdx = interestsVal.indexOf(
                              interest.id,
                            )
                            return (
                              <button
                                key={interest.id}
                                className="flex flex-col items-center focus:outline-none "
                                type="button"
                                onClick={() => {
                                  if (selectedIdx >= 0) {
                                    remove(selectedIdx)
                                  } else {
                                    push(interest.id)
                                  }
                                }}
                              >
                                <SVG
                                  key={`${interest.id}_${selectedIdx >= 0}`}
                                  src={`${process.env.RAZZLE_API_URL}/${interest.featuredImage.url}`}
                                  width="120"
                                  height="100"
                                  fill="gray"
                                  preProcessor={
                                    selectedIdx >= 0 ? null : graySVG
                                  }
                                />
                                <p
                                  className={`font-semibold text-base md:text-22 text-center mt-4 ${
                                    selectedIdx >= 0
                                      ? 'text-blue'
                                      : 'text-lightGray5'
                                  }`}
                                >
                                  {TranslationsService.getLanguage() === 'mk'
                                    ? interest.name
                                    : interest.name_sq}
                                </p>
                              </button>
                            )
                          })}
                      </>
                    )
                  }}
                </FieldArray>
                <TranslatedErrorMessage name={'interests'} />
              </section>
              <div className="flex justify-end mt-10 md:mt-20 px-4 md:px-0">
                <button
                  type="submit"
                  disabled={isUploading}
                  className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-lg font-semibold rounded-xl text-white bg-blue hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-20"
                >
                  {formatMessage({ id: 'save' })}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  municipalities: getMunicipalities(state),
  interests: getInterests(state),
  userDetails: getUserDetails(state),
  industries: getIndustries(state),
})

const mapDispatchToProps = {
  updateMe,
  getProfile,
  listIndustries,
  openModal,
  closeModal,
  setLanguage,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(CompleteProfile),
  true,
  false,
)
