import { openModal, deleteAccount } from '@mzaednica/core'
import { DELETE_PROFILE_MODAL_ID } from '../../consts/modalIds'
import WithConnectedModal from '../../utils/withConnectedModal'
import { connect } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useIntl } from 'react-intl'
import DeleteIcon from '../../img/delete_icon.svg'

const DeleteAccountModal = ({ isOpen = false, closeModal, deleteAccount }) => {
  const { formatMessage } = useIntl()

  const deleteProfile = async () => {
    await deleteAccount()
    closeModal()
    window.location.replace('/')
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="relative bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                <div className="flex justify-end m-2">
                  <button
                    type="button"
                    className="focus:outline-none focus:ring-0 text-gray-500 hover:text-gray-700 p-2"
                    onClick={closeModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="px-6 py-4">
                  <h2 className="text-lg font-semibold text-gray-900 text-center">
                    {formatMessage({ id: 'deleteProfileModalTitle' })}
                  </h2>
                  <p className="text-sm text-gray-600 mt-2 text-center">
                    {formatMessage({ id: 'deleteProfileModalDescription' })}
                  </p>
                </div>
                <div className="px-6 py-4 flex justify-center">
                  <button
                    type="button"
                    className="flex flex-row justify-between items-center px-4 py-2 bg-blue text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none"
                    onClick={deleteProfile}
                  >
                    {formatMessage({ id: 'deleteProfile' })}
                    <img
                      alt="someAlt"
                      src={DeleteIcon}
                      className="w-4 h-6 ml-3"
                    />
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const mapDispatchToProps = {
  openModal,
  deleteAccount,
}

export default WithConnectedModal(
  DELETE_PROFILE_MODAL_ID,
  connect(null, mapDispatchToProps)(DeleteAccountModal),
)
