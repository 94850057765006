import { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from '../components/Header'
import Footer from '../components/Footer'
import NotificationCard from '../components/Notifications/NotificationCard'
import withAuthentication from '../utils/withAuthentication'
import { listNotifications, getNotifications } from '@mzaednica/core'

const Notifications = ({ listNotifications, notificationList }) => {
  useEffect(() => {
    listNotifications({ _sort: 'updated_at:DESC' })
  }, [listNotifications])
  return (
    <>
      <Header />
      <div className="container mx-auto mb-10">
        {notificationList.map((item) => (
          <>
            <div className="mt-3 mr-3 ml-3">
              <NotificationCard notification={item}></NotificationCard>
            </div>
          </>
        ))}
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  notificationList: getNotifications(state),
})

const mapDispatchToProps = {
  listNotifications,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Notifications),
)
