import Footer from '../components/Footer'
import { useIntl } from 'react-intl'
import Header from '../components/Header'
import { LABELS } from '../utils/labels'
const About = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <Header />
      <div className="container mx-auto pt-5 pb-10 md">
        <h3 className="text-center">{formatMessage({ id: 'contactAs' })}</h3>
        <p>
          {formatMessage({ id: 'contact' })}: {LABELS.contactMail}
        </p>
        <p>
          {formatMessage({ id: 'support' })}: {LABELS.supportMail}
        </p>
      </div>
      <Footer />
    </>
  )
}
export default About
