export const reportTypes = [
  {
    value: 'problem',
    label_mk: 'Проблем',
    label_sq: 'Një problem',
  },
  {
    value: 'suggestion',
    label_mk: 'Предлог',
    label_sq: 'Propozim',
  },
]
