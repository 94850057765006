import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThreeDots } from 'react-loading-icons'
import EducationalFacilityCard from '../../components/EducationalFacility/EducationalFacilityCard'
import KindergartenEnrolment from '../../components/EducationalFacility/KindergartenEnrolment'
import SchoolsGray from '../../img/schools_gray.svg'
import SchoolsBlue from '../../img/schools_blue.svg'
import KindergartensBlue from '../../img/kindergartens_blue.svg'
import KindergartensGray from '../../img/kindergartens_gray.svg'
import {
  listEducationalFacilities,
  getEducationalFacilities,
} from '@mzaednica/core'
import withAuthentication from '../../utils/withAuthentication'

const Enrolments = ({ listEducationalFacilities, educationalFacilityList }) => {
  const { isFetching, listData } = educationalFacilityList
  const [currentTab, setCurrentTab] = useState(0)
  const { formatMessage } = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (currentTab === 0) {
      listEducationalFacilities({
        type: 'school',
      })
    } else {
      listEducationalFacilities({
        type: 'kindergarten',
      })
    }
  }, [currentTab, listEducationalFacilities])

  return (
    <>
      <section className="pt-8 pb-40">
        <div className="container flex items-center justify-center space-x-5 flex-wrap mb-7">
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentTab === 0
                ? 'text-white bg-blue'
                : 'text-lightGray2 bg-lightGray1 focus:outline-none'
            }`}
            onClick={setCurrentTab.bind(null, 0)}
          >
            <img
              src={currentTab === 0 ? SchoolsGray : SchoolsBlue}
              alt="School icon"
              width="28"
              height="auto"
            />
            <span className="ml-4 md:ml-8">
              {formatMessage({ id: 'schools' })}
            </span>
          </button>
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full py-2 px-7 mb-4 focus:outline-none ${
              currentTab === 1
                ? 'text-white bg-blue'
                : 'text-lightGray2 bg-lightGray1 focus:outline-none'
            }`}
            onClick={setCurrentTab.bind(null, 1)}
          >
            <img
              src={currentTab === 1 ? KindergartensGray : KindergartensBlue}
              alt="Kindergarten enrolment"
              width="28"
              height="auto"
            />
            <span className="ml-4 md:ml-8"></span>
          </button>
        </div>
        {currentTab === 0 && (
          <>
            {isFetching ? (
              <div className="mx-auto" style={{ width: '120px' }}>
                <ThreeDots fill="rgb(0, 113, 188)" />
              </div>
            ) : listData.map(
                (school) =>
                  school.enrolmentEndDate &&
                  school.enrolmentStartDate &&
                  school.meetingDuration &&
                  school.hasActiveEnrolment,
              ).length === 0 ? (
              <div className="text-center">
                {formatMessage({ id: 'notActiveEnrollmentsInSchool' })}
              </div>
            ) : (
              <div className="container gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-4 lg:px-0">
                {listData.map((item) => {
                  if (
                    item.enrolmentEndDate &&
                    item.enrolmentStartDate &&
                    item.meetingDuration &&
                    item.hasActiveEnrolment
                  ) {
                    return (
                      <EducationalFacilityCard
                        educationalFacility={item}
                        key={item.id}
                      />
                    )
                  }
                })}
              </div>
            )}
          </>
        )}

        {currentTab === 1 && (
          <div className="container gap-30 px-4 lg:px-0">
            {listData === 0 ? (
              <div>Нема активни уписи во градинки во моментов!</div>
            ) : (
              <KindergartenEnrolment kindergartens={listData} />
            )}
          </div>
        )}
      </section>
    </>
  )
}

const mapStateToProps = (state) => ({
  educationalFacilityList: getEducationalFacilities(state),
})

const mapDispatchToProps = {
  listEducationalFacilities,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Enrolments),
)
